export default {
  path: '/thematicManagement',
  title: '专题管理',
  header: 'home',
  icon: 'ios-podium',
  auth: ['thematicManagement'],
  children: [{
    path: "/thematicManagement/thematicList",
    title: '专题列表',
    auth: ['thematicManagement', 'thematicList']
  }]
};