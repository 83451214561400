import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/surveyManage',
  name: 'surveyManage',
  component: BasicLayout,
  redirect: {
    name: 'surveyList'
  },
  meta: meta,
  children: [{
    path: 'surveyList',
    name: 'surveyList',
    meta: {
      title: '问卷列表',
      auth: ['surveyManage', 'surveyList']
    },
    component: function component() {
      return import('@/pages/activityOperate/surveyManage/index.vue');
    }
  }, {
    path: 'createSurveyList',
    name: 'createSurveyList',
    meta: {
      title: '创建问卷列表',
      showSider: true,
      auth: ['surveyManage', 'createSurveyList']
    },
    component: function component() {
      return import('@/pages/activityOperate/surveyManage/createSurveyList.vue');
    }
  }, {
    path: 'surveyStatistics',
    name: 'surveyStatistics',
    meta: {
      title: '问卷统计',
      showSider: true,
      auth: ['surveyManage', 'surveyStatistics']
    },
    component: function component() {
      return import('@/pages/activityOperate/surveyManage/statistics.vue');
    }
  }]
};