import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _ from 'lodash';

/**
 * 根据当前路由的meta.children是否包含来源路由的name来判断，来源路由是否为当前路由的子路由
 * @param {*} to 
 * @param {*} from 
 */
var routerWatcher = function routerWatcher(to, from) {
  var routerChildren = _.get(to, 'meta.children') || [];
  var fromName = _.get(from, 'name');
  var fromChild = false;
  if (routerChildren.includes(fromName)) {
    fromChild = true;
  }
  window.__HISTORY = {
    to: to,
    from: from,
    fromChild: fromChild
  };
};
export default routerWatcher;