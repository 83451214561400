import request from '@/plugins/request';
import Setting from '@/setting';
var pre = 'role/';

// 获取所有角色
export function RoleListAll(params) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "listAll"),
    method: 'get',
    params: params
  });
}

// 角色列表
export function RoleList(params) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "list"),
    method: 'get',
    params: params
  });
}

// 创建角色
export function CreateRole(data) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "create"),
    method: 'post',
    data: data
  });
}

// 修改角色
export function UpdateRole(data) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "update/").concat(data.id),
    method: 'post',
    data: data
  });
}

// 角色详情
export function RoleDetail(id) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre).concat(id),
    method: 'get'
  });
}

// 删除角色
export function DeleteRole(params) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "delete"),
    method: 'post',
    params: params
  });
}

// 分配角色
export function DistriRole(params) {
  return request({
    url: "".concat(Setting.api_prefix.admin, "admin/").concat(pre, "update"),
    method: 'post',
    params: params
  });
}

// 给角色分配菜单
export function AllocMenu(params) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "allocMenu"),
    method: 'post',
    params: params
  });
}

// 获取角色相关菜单
export function GetAllocMenu(id) {
  return request({
    url: "".concat(Setting.api_prefix.admin).concat(pre, "listMenu/").concat(id),
    method: 'get'
  });
}
// 获取加密rsa公钥
export function getRsaPublicKey() {
  return request({
    url: "".concat(Setting.apiBaseURL).concat(Setting.api_prefix.admin, "admin/rasPublicKey"),
    method: 'get'
  });
}
export function getJssdk(params) {
  return request({
    url: "".concat(Setting.apiBaseURL, "/app-visit/staff/jssdk?url=").concat(params.url),
    method: 'get',
    params: params
  });
}