import BasicLayout from '@/layouts/basic-layout';
import EmptyLayout from '@/layouts/empty-layout';
var meta = {
  auth: true
};
export default {
  path: '/enterpriseReport',
  name: 'enterpriseReport',
  component: BasicLayout,
  redirect: {
    name: 'professionalFieldList'
  },
  meta: meta,
  children: [{
    path: 'dashboard',
    name: 'enterpriseReportDashboard',
    meta: {
      title: '数据看板',
      auth: ['enterpriseReport', 'enterpriseReportDashboard']
    },
    component: function component() {
      return import('@/pages/enterprise/dashboard');
    }
  }, {
    path: 'figure',
    name: 'enterpriseReportFigure',
    meta: {
      title: '企业画像',
      showSider: true,
      auth: ['enterpriseReport', 'enterpriseReportFigure']
    },
    component: function component() {
      return import('@/pages/enterprise/figure');
    }
  }, {
    path: 'member',
    name: 'enterpriseReportMember',
    meta: {
      title: '用户画像',
      showSider: true,
      auth: ['enterpriseReport', 'enterpriseReportMember']
    },
    component: function component() {
      return import('@/pages/enterprise/member');
    }
  }, {
    path: 'linkPact',
    name: 'enterpriseReportLinkPact',
    meta: {
      title: '关联合同业务',
      showSider: true,
      auth: ['enterpriseReport', 'enterpriseReportLinkPact']
    },
    component: function component() {
      return import('@/pages/enterprise/linkPact');
    }
  }]
};