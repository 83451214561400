import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
/*
 * @Author: your name
 * @Date: 2021-08-31 09:52:51
 * @LastEditTime: 2021-11-30 14:02:50
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gxb-app-admin\src\filters\index.js
 */
import { sexMap, approveMap, newsTypeMap, noticeTypeMap } from '@/constant';
export default {
  objectToArray: function objectToArray(val, name) {
    return val ? [{
      url: val,
      name: name || val
    }] : undefined;
  },
  arrayToText: function arrayToText(arr) {
    var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
    var text = '';
    if (arr && arr.length) {
      var textArr = [];
      arr.forEach(function (item, index) {
        if (item && item[field]) textArr.push(item[field]);
      });
      text = textArr.join('、');
    }
    return text;
  },
  showApprove: function showApprove(val) {
    return approveMap[val] || '未审核';
  },
  showAge: function showAge(strBirthday) {
    if (strBirthday) {
      var returnAge,
        strBirthdayArr = strBirthday.split("-"),
        birthYear = strBirthdayArr[0],
        birthMonth = strBirthdayArr[1],
        birthDay = strBirthdayArr[2],
        d = new Date(),
        nowYear = d.getFullYear(),
        nowMonth = d.getMonth() + 1,
        nowDay = d.getDate();
      if (nowYear == birthYear) {
        returnAge = 0; //同年 则为0周岁
      } else {
        var ageDiff = nowYear - birthYear; //年之差
        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay; //日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          } else {
            var monthDiff = nowMonth - birthMonth; //月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          }
        } else {
          returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
        }
      }
      return returnAge; //返回周岁年龄
    }
    return '--';
  },
  showFileSize: function showFileSize() {
    var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var size = "";
    if (limit < 0.1 * 1024) {
      //如果小于0.1KB转化成B
      size = limit.toFixed(2) + "B";
    } else if (limit < 0.1 * 1024 * 1024) {
      // 如果小于0.1MB转化成KB
      size = (limit / 1024).toFixed(2) + "KB";
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
      // 如果小于0.1GB转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + "MB";
    } else {
      //其他转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    var sizestr = size + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {
      //当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
  },
  // 秒转时分秒
  formatSeconds: function formatSeconds() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var result = parseInt(value);
    var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    var m = Math.floor(result / 60 % 60) < 10 ? '0' + Math.floor(result / 60 % 60) : Math.floor(result / 60 % 60);
    var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
    var res = "".concat(h, ":").concat(m, ":").concat(s);
    return res;
  }
};