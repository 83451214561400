import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
var sortBy = function sortBy(filed, rev, primer) {
  rev = rev ? -1 : 1;
  return function (a, b) {
    a = a[filed];
    b = b[filed];
    if (typeof primer != 'undefined') {
      a = primer(a);
      b = primer(b);
    }
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev * 1;
    }
    return 1;
  };
};
export var map2Options = function map2Options(obj, isReverse) {
  var arr = [];
  for (var k in obj) {
    arr.push({
      label: obj[k].text,
      value: +k
    });
  }
  if (isReverse) arr.reverse();
  return arr;
};
export var buildTree = function buildTree(arr) {
  var temp = {};
  var tree = {};
  arr.forEach(function (item) {
    temp[item.id] = item;
  });
  var tempKeys = Object.keys(temp);
  tempKeys.forEach(function (key) {
    var item = temp[key];
    var _itemPId = item.pid;
    var parentItemByPid = temp[_itemPId];
    if (parentItemByPid) {
      if (!parentItemByPid.childs) {
        parentItemByPid.childs = [];
      }
      item.title = item.name;
      item.value = item.id;
      parentItemByPid.childs.push(item);
    } else {
      item.title = item.name;
      item.value = item.id;
      tree[item.id] = item;
    }
  });
  return Object.keys(tree).map(function (key) {
    return tree[key];
  });
};
export function getArrParams(arr, key) {
  console.log(arr);
  var str = "?".concat(key, "=");
  var params = arr.join("&".concat(key, "="));
  console.log(str + params);
  return str + params;
}

// 时分秒转秒
export function timeToSecond(time) {
  var hour = time.split(':')[0];
  var min = time.split(':')[1];
  var sec = time.split(':')[2];
  var s = Number(hour * 3600) + Number(min * 60) + Number(sec);
  return s;
}

// 秒转时分秒
export function secondToTime(time) {
  var min = Math.floor(time % 3600);
  return Math.floor(time / 3600) + ":" + Math.floor(min / 60) + ":" + time % 60;
}

/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export function getDaysBetween(dateString1, dateString2) {
  var startDate = new Date(dateString1).getTime();
  var endDate = new Date(dateString2).getTime();
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}