import BasicLayout from '@/layouts/basic-layout';
import EmptyLayout from '@/layouts/empty-layout';
var meta = {
  auth: true
};
export default {
  path: '',
  name: 'operateManagement',
  redirect: {
    name: 'userManagement'
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'userManagement',
    name: 'userManagement',
    redirect: {
      name: 'userList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'userList',
      name: 'userList',
      meta: {
        title: '用户管理',
        auth: ['operateManagement', 'userManagement', 'userList']
      },
      component: function component() {
        return import('@/pages/operate/userManagement/userList');
      }
    }, {
      path: 'userDetail',
      name: 'userDetail',
      meta: {
        title: '用户详情',
        showSider: true,
        auth: ['operateManagement', 'userManagement', 'userDetail']
      },
      component: function component() {
        return import('@/pages/operate/userManagement/userDetail');
      }
    }, {
      path: 'levelList',
      name: 'levelList',
      meta: {
        title: '会员等级管理',
        auth: ['operateManagement', 'userManagement', 'levelList']
      },
      component: function component() {
        return import('@/pages/operate/userManagement/levelList');
      }
    }]
  }, {
    path: 'expertManagement',
    name: 'expertManagement',
    redirect: {
      name: 'expertList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'expertList',
      name: 'expertList',
      meta: {
        title: '专家管理',
        auth: ['operateManagement', 'expertManagement', 'expertList']
      },
      component: function component() {
        return import('@/pages/operate/expertManagement/expertList');
      }
    }, {
      path: 'expertDetail',
      name: 'expertDetail',
      meta: {
        title: '专家详情',
        showSider: true,
        auth: ['operateManagement', 'expertManagement', 'expertDetail']
      },
      component: function component() {
        return import('@/pages/operate/expertManagement/expertDetail');
      }
    }]
  }, {
    path: 'noticeManagement',
    name: 'noticeManagement',
    redirect: {
      name: 'noticeList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'noticeList',
      name: 'noticeList',
      meta: {
        title: '通知管理',
        auth: ['operateManagement', 'noticeManagement', 'noticeList']
      },
      component: function component() {
        return import('@/pages/operate/noticeManagement/noticeList');
      }
    }]
  }, {
    path: 'newsManagement',
    name: 'newsManagement',
    redirect: {
      name: 'newsList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'newsList',
      name: 'newsList',
      meta: {
        title: '资讯管理',
        auth: ['operateManagement', 'newsManagement', 'newsList']
      },
      component: function component() {
        return import('@/pages/operate/newsManagement/newsList');
      }
    }, {
      path: 'newsDetail',
      name: 'newsDetail',
      meta: {
        title: '资讯详情',
        showSider: true,
        auth: ['operateManagement', 'newsManagement', 'newsDetail']
      },
      component: function component() {
        return import('@/pages/operate/newsManagement/newsDetail');
      }
    }, {
      path: 'editNews',
      name: 'editNews',
      meta: {
        title: '编辑资讯',
        showSider: true,
        auth: ['operateManagement', 'newsManagement', 'editNews']
      },
      component: function component() {
        return import('@/pages/operate/newsManagement/editNews');
      }
    }, {
      path: 'newsTagList',
      name: 'newsTagList',
      meta: {
        title: '资讯标签管理',
        auth: ['operateManagement', 'newsManagement', 'newsTagList']
      },
      component: function component() {
        return import('@/pages/operate/newsManagement/newsTagList');
      }
    }]
  }, {
    path: 'plateManagement',
    name: 'plateManagement',
    redirect: {
      name: 'plateList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'plateList',
      name: 'plateList',
      meta: {
        title: '板块管理',
        auth: ['operateManagement', 'plateManagement', 'plateList']
      },
      component: function component() {
        return import('@/pages/operate/plateManagement/plateList');
      }
    }]
  }, {
    path: 'plateManagement',
    name: 'plateManagement',
    redirect: {
      name: 'editPlate'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'editPlate',
      name: 'editPlate',
      meta: {
        title: '编辑板块',
        auth: ['operateManagement', 'plateManagement', 'editPlate']
      },
      component: function component() {
        return import('@/pages/operate/plateManagement/editPlate');
      }
    }]
  }, {
    path: 'organizationManagement',
    name: 'organizationManagement',
    redirect: {
      name: 'organization'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'organization',
      name: 'organization',
      meta: {
        title: '组织管理',
        auth: ['operateManagement', 'organizationManagement', 'organization']
      },
      component: function component() {
        return import('@/pages/operate/organizationManagement/organization');
      }
    }]
  }, {
    path: 'commentManagement',
    name: 'commentManagement',
    redirect: {
      name: 'commentList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'commentList',
      name: 'commentList',
      meta: {
        title: '评论管理',
        auth: ['operateManagement', 'commentManagement', 'commentList']
      },
      component: function component() {
        return import('@/pages/operate/commentManagement/commentList/index');
      }
    }]
  }, {
    path: 'messageNotificationManagement',
    name: 'messageNotificationManagement',
    redirect: {
      name: 'messageNotification'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'messageNotification',
      name: 'messageNotification',
      meta: {
        title: '消息管理',
        auth: ['operateManagement', 'messageNotificationManagement', 'messageNotification']
      },
      component: function component() {
        return import('@/pages/operate/messageNotificationManagement/messageNotification/index');
      }
    }]
  }, {
    path: 'evaluateManagement',
    name: 'evaluateManagement',
    redirect: {
      name: 'evaluateList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'evaluateList',
      name: 'evaluateList',
      meta: {
        title: '评价管理',
        auth: ['operateManagement', 'evaluateManagement', 'evaluateList']
      },
      component: function component() {
        return import('@/pages/operate/evaluateManagement/evaluateList');
      }
    }]
  }, {
    path: 'plateTagList',
    name: 'plateTagList',
    meta: {
      title: '板块标签',
      auth: ['operate', 'plateTagList']
    },
    component: function component() {
      return import('@/pages/operate/plateTagList/index.vue');
    }
  }, {
    path: 'sensitiveWordsManagement',
    name: 'sensitiveWordsManagement',
    redirect: {
      name: 'sensitiveWordsList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'sensitiveWordsList',
      name: 'sensitiveWordsList',
      meta: {
        title: '敏感词管理',
        auth: ['operateManagement', 'sensitiveWordsManagement', 'sensitiveWordsList']
      },
      component: function component() {
        return import('@/pages/operate/sensitiveWordsManagement/sensitiveWordsList');
      }
    }]
  }]
};