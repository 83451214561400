var state = {
  homeBannerList: [],
  infoAdverts: [],
  bottomMenuList: [],
  qrCodeList: [],
  sidebarList: [],
  businessConsultList: [],
  serveInfoList: [],
  temporaryStorage: {}
};
var mutations = {
  updateState: function updateState(state) {
    var payLoad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    for (var i in payLoad) {
      state[i] = payLoad[i];
    }
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};