import request, { serviceTwo } from '@/plugins/request';
import Setting from '@/setting';
import { Base64 } from 'js-base64';

// 用户名登录
export function AccountLogin(data) {
  // var fromData = new FormData()
  // fromData.append('username', data.username)
  // fromData.append('password', data.password)
  return request({
    url: "".concat(Setting.apiBaseURL).concat(Setting.api_prefix.admin, "admin/login"),
    method: 'post',
    // headers: {
    //   'Authorization': 'Basic ' + Base64.encode(Setting.client_id + ":" + Setting.client_secret),
    //   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    // },
    data: data
  });
}

// 刷新token
export function refreshLogin(data) {
  var fromData = new FormData();
  fromData.append('scope', 'app');
  fromData.append('grant_type', 'refresh_token');
  fromData.append('refresh_token', data);
  return serviceTwo({
    url: "".concat(Setting.apiBaseURL, "/app-auth/oauth/token"),
    method: 'post',
    headers: {
      Authorization: 'Basic ' + Base64.encode(Setting.client_id + ':' + Setting.client_secret),
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    data: fromData
  });
}
export function ResetPassword(data) {
  return request({
    url: "".concat(Setting.apiBaseURL).concat(Setting.api_prefix.admin, "admin/updatePassword"),
    method: 'post',
    data: data
  });
}

// 注册
export function AccountRegister(data) {
  return request({
    url: "".concat(Setting.apiBaseURL, "api/register"),
    method: 'post',
    data: data
  });
}
export function GetUserInfo() {
  return request({
    url: "".concat(Setting.apiBaseURL).concat(Setting.api_prefix.admin, "admin/info"),
    method: 'get'
  });
}