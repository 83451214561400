import BasicLayout from "@/layouts/basic-layout";
var meta = {
  auth: true
};
export default {
  path: "/forumManagement",
  name: "forumManagement",
  redirect: {
    name: "forumList"
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: "forumList",
    name: "forumList",
    meta: {
      title: "赛宝观点列表",
      auth: ["forumManagement", "forumList"]
    },
    component: function component() {
      return import("@/pages/forumManagement/forumList");
    }
  }, {
    path: "forumLabelList",
    name: "forumLabelList",
    meta: {
      title: "赛宝观点标签",
      auth: ["forumManagement", "forumLabelList"]
    },
    component: function component() {
      return import("@/pages/forumManagement/forumLabelList");
    }
  }, {
    path: "forumPosts",
    name: "forumPosts",
    meta: {
      title: "帖子管理",
      showSider: true,
      auth: ["forumManagement", "forumList"]
    },
    component: function component() {
      return import("@/pages/forumManagement/forumPostList");
    }
  }, {
    path: "editPost",
    name: "editPost",
    meta: {
      title: "编辑帖子",
      showSider: true,
      auth: ["forumManagement", "forumList"]
    },
    component: function component() {
      return import("@/pages/forumManagement/editPost");
    }
  }, {
    path: "forumDetail",
    name: "forumDetail",
    meta: {
      title: "帖子详情",
      showSider: true,
      auth: ["forumManagement", "forumList"]
    },
    component: function component() {
      return import("@/pages/forumManagement/forumDetail");
    }
  }]
};