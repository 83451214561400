import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/consultationManagement',
  name: 'consultationManagement',
  redirect: {
    name: 'consultationList'
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'consultationList',
    name: 'consultationList',
    meta: {
      title: '咨询列表',
      auth: ['consultationManagement', 'consultationList']
    },
    component: function component() {
      return import('@/pages/consultationManagement/consultationList');
    }
  }, {
    path: 'consultationDetail',
    name: 'consultationDetail',
    meta: {
      title: '咨询详情',
      showSider: true,
      auth: ['consultationManagement', 'consultationList']
    },
    component: function component() {
      return import('@/pages/consultationManagement/consultationDetail');
    }
  }]
};