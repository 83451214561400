import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/',
  name: 'dashboardIndex',
  component: BasicLayout,
  children: [{
    path: 'dashboard',
    name: 'dashboard',
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '主控台',
      closable: false,
      auth: ['dataOperate', 'dashboard']
    }),
    component: function component() {
      return import('@/pages/dataOperate/dataBoard');
    }
  }, {
    path: 'loginLog',
    name: 'loginLog',
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '登陆日志',
      closable: false,
      auth: ['loginLogIndex', 'loginLog']
    }),
    component: function component() {
      return import('@/pages/loginLog/index');
    }
  }, {
    path: 'scrollText',
    name: 'ScrollText',
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公告',
      closable: false,
      auth: ['scrollTextIndex', 'scrollText']
    }),
    component: function component() {
      return import('@/pages/scrollText/index');
    }
  }]
};