import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/learnManagement',
  name: 'learnManagement',
  redirect: {
    name: 'videoList'
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'videoList',
    name: 'videoList',
    meta: {
      title: '视频列表',
      auth: ['learnManagement', 'videoList']
    },
    component: function component() {
      return import('@/pages/learnManagement/videoList');
    }
  }, {
    path: 'videoCategory',
    name: 'videoCategory',
    meta: {
      title: '视频分类',
      auth: ['learnManagement', 'videoCategory']
    },
    component: function component() {
      return import('@/pages/learnManagement/videoCategory');
    }
  }]
};