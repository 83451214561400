import BasicLayout from '@/layouts/basic-layout';
import EmptyLayout from '@/layouts/empty-layout';
var meta = {
  auth: true
};
export default {
  path: '/pcSaiBaoManagement',
  name: 'pcSaiBaoManagement',
  component: BasicLayout,
  redirect: {
    name: 'homeSetting'
  },
  meta: meta,
  children: [{
    path: 'companyCertification',
    name: 'companyCertification',
    meta: {
      title: '企业认证',
      auth: ['pcSaiBaoManagement', 'companyCertification']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/companyCertification');
    }
  }, {
    path: 'homeSetting',
    name: 'homeSetting',
    meta: {
      title: '首页配置',
      auth: ['pcSaiBaoManagement', 'homeSetting']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/homeSetting');
    }
  }, {
    path: 'solutionSetting',
    name: 'solutionSetting',
    meta: {
      title: '解决方案',
      auth: ['pcSaiBaoManagement', 'solutionSetting']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/solutionSetting');
    }
  }, {
    path: 'createSolution',
    name: 'createSolution',
    meta: {
      title: '解决方案',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createSolution']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/solutionSetting/createSolution');
    }
  }, {
    path: 'offlineTraining',
    name: 'offlineTraining',
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'offlineTrainingList',
      name: 'offlineTrainingList',
      meta: {
        title: '线下培训',
        auth: ['pcSaiBaoManagement', 'offlineTraining', 'offlineTrainingList']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/offlineTraining/offlineTrainingList');
      }
    }, {
      path: 'offlineTrainingLabel',
      name: 'offlineTrainingLabel',
      meta: {
        title: '标签分类',
        auth: ['pcSaiBaoManagement', 'offlineTraining', 'offlineTrainingLabel']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/offlineTraining/offlineTrainingLabel');
      }
    }, {
      path: 'createOfflineTraining',
      name: 'createOfflineTraining',
      meta: {
        title: '创建线下培训',
        showSider: true,
        auth: ['pcSaiBaoManagement', 'offlineTraining', 'createOfflineTraining']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/offlineTraining/offlineTrainingList/createOfflineTraining');
      }
    }]
  }, {
    path: 'companyDetail',
    name: 'companyDetail',
    meta: {
      title: '企业认证详情',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'companyDetail']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/companyCertification/companyDetail');
    }
  }, {
    path: 'solutionLabelCategory',
    name: 'solutionLabelCategory',
    meta: {
      title: '解决方案标签分类',
      auth: ['pcSaiBaoManagement', 'solutionLabelCategory']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/solutionLabelCategory');
    }
  }, {
    path: 'createSolutionLabelCategory',
    name: 'createSolutionLabelCategory',
    meta: {
      title: '创建解决方案标签分类',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createSolutionLabelCategory']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/solutionLabelCategory/createSolutionLabelCategory');
    }
  }, {
    path: 'areaManage',
    name: 'areaManage',
    meta: {
      title: '区域管理',
      auth: ['pcSaiBaoManagement', 'areaManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/areaManage');
    }
  }, {
    path: 'whitePaper',
    name: 'whitePaper',
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'whitePaperList',
      name: 'whitePaperList',
      meta: {
        title: '白皮书',
        auth: ['pcSaiBaoManagement', 'whitePaper', 'whitePaperList']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/whitePaper/whitePaperList');
      }
    }, {
      path: 'labelCategory',
      name: 'labelCategory',
      meta: {
        title: '标签分类',
        auth: ['pcSaiBaoManagement', 'whitePaper', 'labelCategory']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/whitePaper/labelCategory');
      }
    }, {
      path: 'createWhitePaper',
      name: 'createWhitePaper',
      meta: {
        title: '白皮书',
        showSider: true,
        auth: ['pcSaiBaoManagement', 'whitePaper', 'createWhitePaper']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/whitePaper/whitePaperList/createWhitePaper');
      }
    }]
  }, {
    path: 'softwareTool',
    name: 'softwareTool',
    meta: {
      title: '软件工具',
      auth: ['pcSaiBaoManagement', 'softwareTool']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/softwareTool');
    }
  }, {
    path: 'createSoftwareTool',
    name: 'createSoftwareTool',
    meta: {
      title: '软件工具',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createSoftwareTool']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/softwareTool/createSoftwareTool');
    }
  }, {
    path: 'applicationCenter',
    name: 'applicationCenter',
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'applicationCenterList',
      name: 'applicationCenterList',
      meta: {
        title: '应用中心',
        auth: ['pcSaiBaoManagement', 'applicationCenter', 'applicationCenterList']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/applicationCenter/applicationCenterList');
      }
    }, {
      path: 'labelCategoryList',
      name: 'labelCategoryList',
      meta: {
        title: '标签分类',
        auth: ['pcSaiBaoManagement', 'applicationCenter', 'labelCategoryList']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/applicationCenter/labelCategoryList');
      }
    }, {
      path: 'createApplicationCenter',
      name: 'createApplicationCenter',
      meta: {
        title: '应用中心',
        showSider: true,
        auth: ['pcSaiBaoManagement', 'applicationCenter', 'createApplicationCenter']
      },
      component: function component() {
        return import('@/pages/pcSaiBaoManagement/applicationCenter/applicationCenterList/createApplicationCenter');
      }
    }]
  }, {
    path: 'reportValidationSummary',
    name: 'reportValidationSummary',
    meta: {
      title: '报告验证汇总',
      auth: ['pcSaiBaoManagement', 'reportValidationSummary']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/reportValidationSummary');
    }
  }, {
    path: 'createReportValidationSummary',
    name: 'createReportValidationSummary',
    meta: {
      title: '报告验证汇总',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createReportValidationSummary']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/reportValidationSummary/createReportValidationSummary');
    }
  }, {
    path: 'progressPageSetting',
    name: 'progressPageSetting',
    meta: {
      title: '进度查询管理配置',
      auth: ['pcSaiBaoManagement', 'reportValidationSummary']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/progressPageSetting');
    }
  }, {
    path: 'createProgressItem',
    name: 'createProgressItem',
    meta: {
      title: '进度查询管理配置',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createReportValidationSummary']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/progressPageSetting/create.vue');
    }
  }, {
    path: 'marketingExpertManage',
    name: 'marketingExpertManage',
    meta: {
      title: '市场专家管理',
      auth: ['pcSaiBaoManagement', 'marketingExpertManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/marketingExpertManage');
    }
  }, {
    path: 'createMarketingExpertManage',
    name: 'createMarketingExpertManage',
    meta: {
      title: '创建市场专家管理',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createMarketingExpertManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/marketingExpertManage/createMarketingExpertManage');
    }
  }, {
    path: 'entrustList',
    name: 'entrustList',
    meta: {
      title: '委托列表',
      auth: ['pcSaiBaoManagement', 'entrustList']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/entrustList');
    }
  }, {
    path: 'trainingResponsiblePerson',
    name: 'trainingResponsiblePerson',
    meta: {
      title: '培训负责人管理',
      auth: ['pcSaiBaoManagement', 'trainingResponsiblePerson']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/trainingResponsiblePerson');
    }
  }, {
    path: 'createTrainingResponsiblePerson',
    name: 'createTrainingResponsiblePerson',
    meta: {
      title: '创建培训负责人',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createTrainingResponsiblePerson']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/trainingResponsiblePerson/createTrainingResponsiblePerson');
    }
  }, {
    path: 'homeThermoChart',
    name: 'homeThermoChart',
    meta: {
      title: '首页热力图',
      auth: ['dataOperate', 'homeThermoChart']
    },
    component: function component() {
      return import('@/pages/dataOperate/homeThermoChart');
    }
  }, {
    path: 'customerSuggest',
    name: 'customerSuggest',
    meta: {
      title: '客户建议',
      auth: ['pcSaiBaoManagement', 'customerSuggest']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/customerSuggest');
    }
  }, {
    path: 'expertManage',
    name: 'expertManage',
    meta: {
      title: '专家库管理',
      auth: ['pcSaiBaoManagement', 'expertManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/expertManage');
    }
  }, {
    path: 'createExpertManage',
    name: 'createExpertManage',
    meta: {
      title: '创建专家库',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createExpertManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/expertManage/createExpertManage');
    }
  }, {
    path: 'expertTagManage',
    name: 'expertTagManage',
    meta: {
      title: '专家标签管理',
      auth: ['pcSaiBaoManagement', 'expertTagManage']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/expertTagManage');
    }
  }, {
    path: 'scienceAchievement',
    name: 'scienceAchievement',
    meta: {
      title: '科技成果',
      auth: ['pcSaiBaoManagement', 'scienceAchievement']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/scienceAchievement');
    }
  }, {
    path: 'createScienceAchievement',
    name: 'createScienceAchievement',
    meta: {
      title: '新建科技成果',
      showSider: true,
      auth: ['pcSaiBaoManagement', 'createScienceAchievement']
    },
    component: function component() {
      return import('@/pages/pcSaiBaoManagement/scienceAchievement/createScienceAchievement.vue');
    }
  }]
};