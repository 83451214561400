import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/formStruManagement',
  name: 'formStruManagement',
  component: BasicLayout,
  meta: meta,
  children: [{
    path: 'list',
    name: 'formStruManagementList',
    meta: {
      title: '表单管理',
      auth: ['formStruManagement', 'formStruManagementIndex']
    },
    component: function component() {
      return import('@/pages/activityOperate/formStruManagement/list');
    }
  }, {
    path: 'edit',
    name: 'formStruManagementEdit',
    meta: {
      title: '表单管理',
      showSider: true,
      auth: ['formStruManagement', 'formStruManagementEdit']
    },
    component: function component() {
      return import('@/pages/activityOperate/formStruManagement/edit');
    }
  }, {
    path: 'formStatist',
    name: 'formStatist',
    meta: {
      title: '表单统计',
      showSider: true,
      auth: ['formStruManagement', 'formStatist']
    },
    component: function component() {
      return import('@/pages/activityOperate/formStruManagement/formStatist');
    }
  }]
};