import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
import "/drone/src/node_modules/core-js/modules/es6.array.iterator.js";
import "/drone/src/node_modules/core-js/modules/es6.promise.js";
import "/drone/src/node_modules/core-js/modules/es6.object.assign.js";
import "/drone/src/node_modules/core-js/modules/es7.promise.finally.js";
// Vue
import Vue from 'vue';
import App from "./App";

// 配置
import Setting from "./setting";

// 混合
import mixinApp from '@/mixins/app';

// 插件
import plugins from '@/plugins';
// 省市区联动
import iRegion from '@/libs/iRegion/region.min.js';
// store
import store from '@/store/index';

// iView 和 iView Pr
import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';

// 菜单和路由
import router from "./router";
import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes';
import "./utils/rem";
import routerWatcher from "./utils/router";

// 多语言
import _i18n from '@/i18n';

// 方法
import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system';

// 内置组件
import iLink from '@/components/link';
import SearchFilter from '@/components/searchFilter.vue';
import apiSelect from '@/components/form/ApiSelect';
import ApiTreeSelect from '@/components/form/apiTreeSelect';
import JSEncrypt from 'jsencrypt';
// 全局方法
import globalOperations from "./utils/globalOptations";

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import "./styles/index.less";
import "./libs/iview-pro/iview-pro.css";
import util from '@/libs/util';
import heatmapjsVue from 'heatmapjs-vue';
import filters from '@/filters/index.js';
if (window) window.$t = function (key, value) {
  return _i18n.t(key, value);
};
Object.keys(filters).forEach(function (k) {
  return Vue.filter(k, filters[k]);
});
Vue.prototype.$jsEncrypt = JSEncrypt;
Vue.use(plugins);
Vue.use(iRegion);
Vue.use(globalOperations);
Vue.use(heatmapjsVue);
Vue.use(ViewUI, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(iViewPro);
Vue.component('i-link', iLink);
Vue.component('SearchFilter', SearchFilter);
Vue.component('ApiSelect', apiSelect);
Vue.component('ApiTreeSelect', ApiTreeSelect);
new Vue({
  mixins: [mixinApp],
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    this.$store.commit('admin/menu/setHeader', menuHeader);
    // 加载用户登录的数据
    this.$store.dispatch('admin/account/load');
    // 初始化全屏监听
    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  mounted: function mounted() {},
  methods: {
    beforeunloadHandler: function beforeunloadHandler(e) {
      // debugger
      this._beforeUnload_time = new Date().getTime();
      console.log('this._beforeUnload_time：', this._beforeUnload_time);
      e = e || window.event;
      if (e) {
        e.returnValue = '关闭提示';
      }
      // debugger
      return '关闭提示';
    }
  },
  beforeDestroy: function beforeDestroy() {
    var _this = this;
    // 删除cookie
    util.cookies.remove('token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('teacher');
    window.localStorage.removeItem('member');
    // 清空 vuex 用户信息
    //   await dispatch('admin/user/set', {}, { root: true })
    window.localStorage('text', '123');
    window.open('www.baidu.com');
    window.removeEventListener('beforeunload', function (e) {
      return _this.beforeunloadHandler(e);
    });
  },
  destroyed: function destroyed() {
    var _this2 = this;
    // 删除cookie
    util.cookies.remove('token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('teacher');
    window.localStorage.removeItem('member');
    // 清空 vuex 用户信息
    //   await dispatch('admin/user/set', {}, { root: true })
    window.removeEventListener('beforeunload', function (e) {
      return _this2.beforeunloadHandler(e);
    });
    window.open('www.baidu.com');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(to, from) {
      routerWatcher(to, from);
      var path = to.path;
      if (!Setting.dynamicSiderMenu) {
        var headerName = getHeaderName(path, menuSider);
        // 在 404 时，是没有 headerName 的
        if (headerName !== null || to.meta.showSider) {
          this.$store.commit('admin/menu/setHeaderName', headerName);
          var filterMenuSider = getMenuSider(menuSider, headerName);
          this.$store.commit('admin/menu/setSider', filterMenuSider);
          this.$store.commit('admin/menu/setActivePath', path);
          var openNames = getSiderSubmenu(path, menuSider);
          this.$store.commit('admin/menu/setOpenNames', openNames);
        }
      }
      this.appRouteChange(to, from);
    }
  }
}).$mount('#app');