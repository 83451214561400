var pre = '/pcSaiBaoManagement/';
var coursePre = '/courseManagement/';
var livePre = '/liveManagement/';
var certificatePre = '/certificateManagement/';
var couponPre = '/couponManagement/';
var news = '/newsManagement/';
export default {
  path: '/operate',
  title: '运营管理',
  header: 'home',
  icon: 'ios-podium',
  auth: ['operateManagement'],
  children: [{
    path: "dataOperate",
    title: '数据运营',
    auth: ['dataOperate'],
    children: [{
      path: '/dashboard',
      title: '数据看板',
      header: 'home',
      auth: ['dataOperate', 'dashboard']
    }, {
      path: "".concat(pre, "homeThermoChart"),
      title: '首页热力图',
      auth: ['dataOperate', 'homeThermoChart']
    }]
  }, {
    path: "activityOperate",
    title: '活动运营',
    auth: ['activityOperate'],
    children: [{
      path: "activityManage",
      title: '活动管理',
      auth: ['activityManage'],
      children: [{
        path: "/activityManagement/activityList",
        title: '活动列表',
        auth: ['activityOperate', 'activityList']
      }, {
        path: '/formStruManagement/list',
        title: '表单管理',
        header: 'home',
        auth: ['formStruManagement']
      }]
    }, {
      path: "surveyManage",
      title: '问卷管理',
      auth: ['surveyManage'],
      children: [{
        path: "/surveyManage/surveyList",
        title: '问卷列表',
        auth: ['surveyManage', 'surveyList']
      }]
    }, {
      path: '/scrollText',
      title: '公告',
      header: 'home',
      auth: ['scrollTextIndex', 'scrollText']
    }, {
      path: '/liveManagement',
      title: '直播管理',
      auth: ['saibaoCourse', 'liveManagement'],
      children: [{
        path: "/liveManagement/liveList",
        title: '直播列表',
        icon: 'md-videocam',
        hideIcon: true,
        auth: ['saibaoCourse', 'liveManagement', 'liveList']
      }, {
        path: "/liveManagement/liveLabelList",
        title: '直播标签',
        auth: ['saibaoCourse', 'liveManagement', 'liveLabelList']
      }]
    }]
  }, {
    path: "contentOperate",
    title: '内容运营',
    auth: ['contentOperate'],
    children: [{
      path: '/learnManagement',
      title: '视频管理',
      auth: ['learnManagement'],
      children: [{
        path: "/learnManagement/videoList",
        title: '视频列表',
        auth: ['learnManagement', 'videoList']
      }, {
        path: "/learnManagement/videoCategory",
        title: '视频分类',
        auth: ['learnManagement', 'videoCategory']
      }]
    }, {
      path: 'solutionManage',
      title: '解决方案管理',
      auth: ['solutionManage'],
      children: [{
        path: "/pcSaiBaoManagement/solutionSetting",
        title: '解决方案',
        auth: ['pcSaiBaoManagement', 'solutionSetting']
      }, {
        path: "/pcSaiBaoManagement/solutionLabelCategory",
        title: '解决方案标签分类',
        auth: ['pcSaiBaoManagement', 'solutionLabelCategory']
      }]
    }, {
      path: 'offlineTrainingManage',
      title: '培训管理',
      auth: ['offlineTrainingManage'],
      children: [{
        path: "/pcSaiBaoManagement/offlineTraining/offlineTrainingList",
        title: '线下培训列表',
        auth: ['pcSaiBaoManagement', 'offlineTraining', 'offlineTrainingList']
      }, {
        path: "/pcSaiBaoManagement/offlineTraining/offlineTrainingLabel",
        title: '线下培训标签',
        auth: ['pcSaiBaoManagement', 'offlineTraining', 'offlineTrainingLabel']
      }]
    }, {
      path: '/course',
      title: '赛宝课堂',
      header: 'home',
      auth: ['saibaoCourse'],
      children: [{
        path: '/courseManagement',
        title: '课程管理',
        auth: ['saibaoCourse', 'courseManagement'],
        children: [{
          path: "".concat(coursePre, "courseList"),
          title: '课程列表',
          icon: 'md-book',
          hideIcon: true,
          auth: ['saibaoCourse', 'courseManagement', 'courseList']
        }, {
          path: "".concat(coursePre, "courseLabelList"),
          title: '课程标签',
          auth: ['saibaoCourse', 'courseManagement', 'courseLabelList']
        }]
      }, {
        path: '/invoiceManagement/invoiceList',
        title: '发票管理',
        auth: ['saibaoCourse', 'invoiceManagement', 'invoiceList']
      }, {
        path: '/orderManagement/orderList',
        title: '订单管理',
        auth: ['saibaoCourse', 'orderManagement', 'orderList']
      }, {
        path: '/couponManagement',
        title: '优惠券管理',
        auth: ['saibaoCourse', 'couponManagement'],
        children: [{
          path: "".concat(couponPre, "couponList"),
          title: '优惠券列表',
          auth: ['saibaoCourse', 'certificateManagement', 'couponList']
        }, {
          path: "".concat(couponPre, "couponStatistics"),
          title: '数据统计',
          auth: ['saibaoCourse', 'certificateManagement', 'couponStatistics']
        }]
      }, {
        path: '/certificateManagement',
        title: '证书管理',
        auth: ['saibaoCourse', 'certificateManagement'],
        children: [{
          path: "".concat(certificatePre, "certificateList"),
          title: '证书列表',
          auth: ['saibaoCourse', 'certificateManagement', 'certificateList']
        }, {
          path: "".concat(certificatePre, "certificateStatistics"),
          title: '数据统计',
          auth: ['saibaoCourse', 'certificateManagement', 'certificateStatistics']
        }]
      }]
    }, {
      path: '/forumManagement',
      title: '赛宝观点管理',
      auth: ['forumManagement'],
      children: [{
        path: "/forumManagement/forumList",
        title: '赛宝观点列表',
        auth: ['forumManagement', 'forumList']
      }, {
        path: "/forumManagement/forumLabelList",
        title: '赛宝观点标签',
        auth: ['forumManagement', 'forumLabelList']
      }, {
        path: '/commentManagement/commentList',
        title: '评论管理',
        auth: ['operateManagement', 'commentManagement']
      }, {
        path: '/sensitiveWordsManagement/sensitiveWordsList',
        title: '敏感词管理',
        auth: ['operateManagement', 'evaluateManagement']
      }]
    }, {
      path: '/newsManagement',
      title: '资讯管理',
      auth: ['operateManagement', 'newsManagement'],
      children: [{
        path: "".concat(news, "newsList"),
        title: '资讯列表',
        auth: ['operateManagement', 'newsManagement', 'newsList']
      }, {
        path: "".concat(news, "newsTagList"),
        title: '资讯标签',
        auth: ['operateManagement', 'newsManagement', 'newsTagList']
      }]
    }, {
      path: 'sourceCenter',
      title: '资源中心',
      auth: ['sourceCenter'],
      children: [{
        path: "".concat(pre, "whitePaper"),
        title: '白皮书管理',
        auth: ['pcSaiBaoManagement', 'whitePaper'],
        children: [{
          path: "".concat(pre, "whitePaper/whitePaperList"),
          title: '白皮书',
          auth: ['pcSaiBaoManagement', 'whitePaper', 'whitePaperList']
        }, {
          path: "".concat(pre, "whitePaper/labelCategory"),
          title: '标签分类',
          auth: ['pcSaiBaoManagement', 'whitePaper', 'labelCategory']
        }]
      }, {
        path: "".concat(pre, "softwareTool"),
        title: '软件工具',
        auth: ['pcSaiBaoManagement', 'softwareTool']
      }, {
        path: "".concat(pre, "applicationCenter"),
        title: '应用中心管理',
        auth: ['pcSaiBaoManagement', 'applicationCenter'],
        children: [{
          path: "".concat(pre, "applicationCenter/applicationCenterList"),
          title: '应用中心',
          auth: ['pcSaiBaoManagement', 'applicationCenter', 'applicationCenterList']
        }, {
          path: "".concat(pre, "applicationCenter/labelCategoryList"),
          title: '标签分类',
          auth: ['pcSaiBaoManagement', 'applicationCenter', 'labelCategoryList']
        }]
      }, {
        path: "".concat(pre, "expertsManage"),
        title: '专家库管理',
        auth: ['pcSaiBaoManagement', 'expertsManage'],
        children: [{
          path: "".concat(pre, "expertManage"),
          title: '专家库列表',
          auth: ['pcSaiBaoManagement', 'expertManage']
        }, {
          path: "".concat(pre, "expertTagManage"),
          title: '专家库标签管理',
          auth: ['pcSaiBaoManagement', 'expertTagManage']
        }]
      }]
    }, {
      path: "/pcSaiBaoManagement/scienceAchievement",
      title: '科技成果',
      auth: ['pcSaiBaoManagement', 'scienceAchievement']
    }]
  }, {
    path: "userOperate",
    title: '用户运营',
    auth: ['userOperate'],
    children: [{
      path: '/consultationManagement',
      title: '咨询管理',
      header: 'home',
      auth: ['consultationManagement'],
      children: [{
        path: "/consultationManagement/consultationList",
        title: '咨询列表',
        hideIcon: true,
        auth: ['consultationManagement', 'consultationList']
      }, {
        path: '/messageNotificationManagement/messageNotification',
        title: '消息通知列表',
        auth: ['operateManagement', 'messageNotificationManagement', 'messageNotification']
      }, {
        path: '/evaluateManagement/evaluateList',
        title: '评价管理',
        auth: ['operateManagement', 'evaluateManagement']
      }]
    }, {
      path: "".concat(pre, "customerSuggest"),
      title: '客户反馈',
      auth: ['pcSaiBaoManagement', 'customerSuggest']
    }, {
      path: "".concat(pre, "entrustList"),
      title: '委托列表',
      auth: ['pcSaiBaoManagement', 'entrustList']
    }]
  }]
};