import BasicLayout from '@/layouts/basic-layout';
import EmptyLayout from '@/layouts/empty-layout';
var meta = {
  auth: true
};
export default {
  path: '',
  name: 'saibaoCourse',
  redirect: {
    name: 'courseManagement'
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: '/courseManagement',
    name: 'courseManagement',
    redirect: {
      name: 'courseList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'courseList',
      name: 'courseList',
      meta: {
        title: '课程列表',
        auth: ['saibaoCourse', 'courseManagement', 'courseList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/courseManagement/courseList');
      }
    }, {
      path: 'editCourse',
      name: 'editCourse',
      meta: {
        title: '编辑课程',
        showSider: true,
        auth: ['saibaoCourse', 'courseManagement', 'courseList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/courseManagement/editCourse');
      }
    }, {
      path: 'courseDetail',
      name: 'courseDetail',
      meta: {
        title: '课程详情',
        showSider: true,
        auth: ['saibaoCourse', 'courseManagement', 'courseList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/courseManagement/courseDetail');
      }
    }, {
      path: 'courseLabelList',
      name: 'courseLabelList',
      meta: {
        title: '课程标签',
        auth: ['saibaoCourse', 'courseManagement', 'courseLabelList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/courseManagement/courseLabelList');
      }
    }]
  }, {
    path: '/liveManagement',
    name: 'liveManagement',
    redirect: {
      name: 'liveList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'liveList',
      name: 'liveList',
      meta: {
        title: '直播管理',
        auth: ['saibaoCourse', 'liveManagement', 'liveList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/liveManagement/liveList');
      }
    }, {
      path: 'liveLabelList',
      name: 'liveLabelList',
      meta: {
        title: '直播标签管理',
        auth: ['saibaoCourse', 'liveManagement', 'liveLabelList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/liveManagement/liveLabelList');
      }
    }, {
      path: 'orderDetail',
      name: 'orderDetail',
      meta: {
        title: '直播预约详情',
        showSider: true,
        auth: ['saibaoCourse', 'liveManagement', 'orderDetail']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/liveManagement/orderDetail');
      }
    }]
  }, {
    path: '/bannerManagement',
    name: 'bannerManagement',
    redirect: {
      name: 'bannerList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'bannerList',
      name: 'bannerList',
      meta: {
        title: 'banner管理',
        auth: ['saibaoCourse', 'bannerManagement', 'bannerList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/bannerManagement/bannerList');
      }
    }]
  }, {
    path: '/companyProfileManagement',
    name: 'companyProfileManagement',
    redirect: {
      name: 'profileList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'profileList',
      name: 'profileList',
      meta: {
        title: '公司简介管理',
        auth: ['saibaoCourse', 'companyProfileManagement', 'profileList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/companyProfileManagement/profileList');
      }
    }]
  }, {
    path: '/invoiceManagement',
    name: 'invoiceManagement',
    redirect: {
      name: 'invoiceList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'invoiceList',
      name: 'invoiceList',
      meta: {
        title: '发票管理',
        auth: ['saibaoCourse', 'invoiceManagement', 'invoiceList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/invoiceManagement/invoiceList');
      }
    }, {
      path: 'invoiceDetail',
      name: 'invoiceDetail',
      meta: {
        title: '发票详情',
        showSider: true,
        auth: ['saibaoCourse', 'invoiceManagement', 'invoiceDetail']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/invoiceManagement/invoiceDetail');
      }
    }]
  }, {
    path: '/orderManagement',
    name: 'orderManagement',
    redirect: {
      name: 'orderList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'orderList',
      name: 'orderList',
      meta: {
        title: '订单列表',
        auth: ['saibaoCourse', 'orderManagement', 'orderList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/orderManagement/orderList');
      }
    }, {
      path: 'orderDetail',
      name: 'orderDetail',
      meta: {
        title: '订单详情',
        showSider: true,
        auth: ['saibaoCourse', 'orderManagement', 'orderDetail']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/orderManagement/orderDetail');
      }
    }]
  }, {
    path: '/couponManagement',
    name: 'couponManagement',
    redirect: {
      name: 'couponList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'couponList',
      name: 'couponList',
      meta: {
        title: '优惠券列表',
        auth: ['saibaoCourse', 'couponManagement', 'couponList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/couponManagement/couponList');
      }
    }, {
      path: 'couponStatistics',
      name: 'couponStatistics',
      meta: {
        title: '统计数据',
        auth: ['saibaoCourse', 'couponManagement', 'couponStatistics']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/couponManagement/couponStatistics');
      }
    }]
  }, {
    path: '/certificateManagement',
    name: 'certificateManagement',
    redirect: {
      name: 'certificateList'
    },
    meta: meta,
    component: EmptyLayout,
    children: [{
      path: 'certificateList',
      name: 'certificateList',
      meta: {
        title: '证书列表',
        auth: ['saibaoCourse', 'certificateManagement', 'certificateList']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/certificateManagement/certificateList');
      }
    }, {
      path: 'editCertificate',
      name: 'editCertificate',
      meta: {
        title: '编辑证书',
        showSider: true,
        auth: ['saibaoCourse', 'certificateManagement', 'editCertificate']
      },
      showSider: true,
      component: function component() {
        return import('@/pages/saibaoCourse/certificateManagement/edit');
      }
    }, {
      path: 'certificateStatistics',
      name: 'certificateStatistics',
      meta: {
        title: '数据统计',
        auth: ['saibaoCourse', 'certificateManagement', 'certificateStatistics']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/certificateManagement/certificateStatistics');
      }
    }, {
      path: 'cerdentialDetail',
      name: 'cerdentialDetail',
      meta: {
        title: '证书详情',
        showSider: true,
        auth: ['saibaoCourse', 'certificateManagement', 'cerdentialDetail']
      },
      component: function component() {
        return import('@/pages/saibaoCourse/certificateManagement/certificateDetail');
      }
    }]
  }]
};