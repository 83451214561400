import _defineProperty from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import { map2Options } from '@/plugins/util';

// 性别
export var sex = {
  man: 1,
  women: 2
};
export var sexMap = _defineProperty(_defineProperty({}, sex.man, {
  text: '男'
}), sex.women, {
  text: '女'
});
export var sexOptions = map2Options(sexMap);

// 课程类型
export var courseType = {
  application: 1,
  tool: 2
};
export var courseTypeMap = _defineProperty(_defineProperty({}, courseType.application, {
  text: '应用'
}), courseType.tool, {
  text: '工具'
});
export var courseTypeOptions = map2Options(courseTypeMap);

// 审核状态
export var approveStatus = {
  unApprove: 100,
  resolve: 200,
  reject: 300
};
export var approveMap = _defineProperty(_defineProperty(_defineProperty({}, approveStatus.unApprove, {
  text: '待审核',
  color: 'yellow'
}), approveStatus.resolve, {
  text: '已通过',
  color: 'blue'
}), approveStatus.reject, {
  text: '已拒绝',
  color: 'red'
});
export var approveOptions = map2Options(approveMap);

// 上架状态
export var shelfStatus = {
  yes: 1,
  no: 0
};
export var shelfMap = _defineProperty(_defineProperty({}, shelfStatus.yes, {
  text: '上架',
  color: 'blue'
}), shelfStatus.no, {
  text: '下架',
  color: 'red'
});
export var shelfOptions = map2Options(shelfMap, true);

// 支付状态
export var payStatus = {
  payed: 200,
  unPay: 100
};
export var payMap = _defineProperty(_defineProperty({}, payStatus.payed, {
  text: '已支付',
  color: 'blue'
}), payStatus.unPay, {
  text: '未支付',
  color: 'red'
});
export var payOptions = map2Options(payMap);

// 分配状态
export var assignStatus = {
  unAssign: 100,
  assigned: 200,
  finish: 300
};
export var assignMap = _defineProperty(_defineProperty(_defineProperty({}, assignStatus.unAssign, {
  text: '未分配',
  color: 'yellow'
}), assignStatus.assigned, {
  text: '已分配',
  color: 'blue'
}), assignStatus.finish, {
  text: '已完成',
  color: 'red'
});
export var assignOptions = map2Options(assignMap);

// 咨询进度
export var ConsuProgress = {
  all: 10,
  Unassigned: 50,
  resolve: 100,
  toBeReplied: 200,
  completed: 300
};
export var progressMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ConsuProgress.all, {
  text: '全部',
  color: 'grenn'
}), ConsuProgress.Unassigned, {
  text: '未分配',
  color: 'black'
}), ConsuProgress.resolve, {
  text: '待解决',
  color: 'red'
}), ConsuProgress.toBeReplied, {
  text: '待确认',
  color: 'blue'
}), ConsuProgress.completed, {
  text: '已完成',
  color: 'grenn'
});
export var progressOptions = map2Options(progressMap);

// 咨询类别
export var consulationType = {
  picText: 100,
  fast: 200
};
export var consulationTypeMap = _defineProperty(_defineProperty({}, consulationType.picText, {
  text: '图文咨询'
}), consulationType.fast, {
  text: '快速咨询'
});
export var consulationOptions = map2Options(consulationTypeMap);

// 回复状态
export var replyStatus = {
  yes: 300,
  no: 100,
  ing: 200
};
export var replyStatusMap = _defineProperty(_defineProperty(_defineProperty({}, replyStatus.yes, {
  text: '已回复',
  color: 'blue'
}), replyStatus.no, {
  text: '未回复',
  color: 'red'
}), replyStatus.ing, {
  text: '进行中',
  color: 'red'
});
export var replyStatusOptions = map2Options(replyStatusMap);

// 专家等级
export var levelType = {
  one: '1',
  two: '2',
  three: '3'
};
export var levelTypeMap = _defineProperty(_defineProperty(_defineProperty({}, levelType.one, {
  text: '初级'
}), levelType.two, {
  text: '中级'
}), levelType.three, {
  text: '高级'
});
export var levelTypeOptions = map2Options(levelTypeMap);

// 专家类型
export var Type = {
  one: 100,
  two: 200,
  three: 300,
  five: 500
};
export var TypeMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Type.one, {
  text: '市场专家'
}), Type.two, {
  text: '技术专家'
}), Type.three, {
  text: '市场负责人'
}), Type.five, {
  text: '市场人员'
});
export var TypeMapOptions = map2Options(TypeMap);

// 资讯类别
export var newsType = {
  pic: 100,
  video: 200
};
export var newsTypeMap = _defineProperty(_defineProperty({}, newsType.pic, {
  text: '图文'
}), newsType.video, {
  text: '视频'
});

// 通知类别
export var noticeType = {
  sys: 100,
  notice: 200
};
export var noticeTypeMap = _defineProperty(_defineProperty({}, noticeType.sys, {
  text: '系统消息'
}), noticeType.notice, {
  text: '公告'
});

// 优惠券状态
export var couponStatus = {
  unStart: 100,
  playing: 200,
  expire: 300
};
export var couponStatusMap = _defineProperty(_defineProperty(_defineProperty({}, couponStatus.unStart, {
  text: '未开始'
}), couponStatus.playing, {
  text: '进行中'
}), couponStatus.expire, {
  text: '已过期'
});
export var couponStatusOptions = map2Options(couponStatusMap);

// 用户来源
export var userSource = {
  miniApp: 100,
  android: 200,
  ios: 300,
  system: 400
};
export var userSourceMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, userSource.miniApp, {
  text: '小程序'
}), userSource.android, {
  text: 'app'
}), userSource.ios, {
  text: 'ios'
}), userSource.system, {
  text: '后台'
});
export var userSourceOptions = map2Options(couponStatusMap);

// 是否所内
export var isSuoneiMap = {
  0: {
    text: '否'
  },
  1: {
    text: '是'
  }
};