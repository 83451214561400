export default {
  path: 'systemSetting',
  title: '系统设置',
  header: 'home',
  icon: 'ios-podium',
  auth: ['systemSetting'],
  children: [{
    path: '/bannerManagement/bannerList',
    title: '小程序banner设置',
    auth: ['saibaoCourse', 'bannerManagement', 'bannerList']
  }, {
    path: '/noticeManagement/noticeList',
    title: '系统通知管理',
    auth: ['operateManagement', 'noticeManagement']
  }, {
    path: '/posterManagement/list',
    title: '广告屏设置',
    header: 'home',
    auth: ['posterManagement']
  }, {
    path: "/pcSaiBaoManagement/homeSetting",
    title: '官网首页设置',
    auth: ['pcSaiBaoManagement', 'homeSetting']
  }, {
    path: '/companyProfileManagement/profileList',
    title: '公司简介设置',
    auth: ['saibaoCourse', 'companyProfileManagement', 'profileList']
  }, {
    path: "plageManage",
    title: '板块管理',
    auth: ['plageManage'],
    children: [{
      path: '/plateManagement/plateList',
      title: '板块列表',
      auth: ['operateManagement', 'plateManagement']
    }, {
      path: '/plateTagList',
      title: '板块标签',
      auth: ['operate', 'plateTagList']
    }]
  }, {
    path: '/organizationManagement/organization',
    title: '组织管理',
    auth: ['operateManagement', 'organizationManagement']
  }, {
    path: "/pcSaiBaoManagement/areaManage",
    title: '区域管理',
    auth: ['pcSaiBaoManagement', 'areaManage']
  }, {
    path: "/pcSaiBaoManagement/reportValidationSummary",
    title: '报告验证汇总',
    auth: ['pcSaiBaoManagement', 'reportValidationSummary']
  }, {
    path: "/pcSaiBaoManagement/progressPageSetting",
    title: '进度查询管理配置',
    auth: ['pcSaiBaoManagement', 'reportValidationSummary']
  }]
};