var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("TreeSelect", _vm._b({
    attrs: {
      data: _vm.options,
      loading: _vm.loading,
      multiple: _vm.multiple,
      "show-checkbox": "",
      clearable: "",
      filterable: ""
    },
    on: {
      "on-change": _vm.handleChange,
      "on-open-change": _vm.handleOpenChange
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, "TreeSelect", _vm.componentProps, false))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };