var state = {
  cacheData: [],
  searchFilterData: {},
  paginationData: {}
};
var mutations = {
  SET_CACHEDATA: function SET_CACHEDATA(state, data) {
    state.cacheData = data;
  },
  updateState: function updateState(state) {
    var payLoad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    for (var i in payLoad) {
      state[i] = payLoad[i];
    }
  }
};
var actions = {
  setCacheData: function setCacheData(_ref, data) {
    var commit = _ref.commit;
    commit('SET_CACHEDATA', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};