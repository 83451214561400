import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Languages from '@/i18n/locale';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'iHeaderI18n',
  data: function data() {
    return {
      languages: Languages
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/i18n', ['locale'])), mapState('admin/layout', ['isMobile'])),
  methods: _objectSpread(_objectSpread({}, mapActions('admin/i18n', ['setLocale'])), {}, {
    handleClick: function handleClick(locale) {
      if (locale === this.locale) return;
      this.setLocale({
        locale: locale,
        vm: this
      });
    }
  })
};