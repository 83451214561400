var user = '/userManagement/';
var pre = '/enterpriseManagement/';
export default {
  path: 'dataBaseManage',
  title: '数据库管理',
  header: 'home',
  icon: 'ios-podium',
  auth: ['dataBaseManage'],
  children: [{
    path: '/expertManagement/expertList',
    title: '市场管理',
    auth: ['operateManagement', 'expertManagement']
  }, {
    path: '/userManagement',
    title: '用户管理',
    auth: ['operateManagement', 'userManagement'],
    children: [{
      path: "".concat(user, "userList"),
      title: '用户画像',
      auth: ['operateManagement', 'userManagement', 'userList']
    }, {
      path: "".concat(user, "levelList"),
      title: '会员等级',
      auth: ['operateManagement', 'userManagement', 'levelList']
    }]
  }
  // {
  //   path: '/enterpriseManagement',
  //   title: '企业管理',
  //   header: 'home',
  //   auth: ['enterpriseManagement'],
  //   children: [
  //     {
  //       path: `${pre}management`,
  //       title: '企业画像',
  //       auth: ['enterpriseManagement', 'enterpriseManagementIndex'],
  //     },
  //     {
  //       path: `${pre}intermediaryManagement`,
  //       title: '企业中间库',
  //       auth: ['enterpriseManagement', 'enterpriseIntermediaryManagement'],
  //     },
  //     {
  //       path: `${pre}pactManagement1`,
  //       title: '合同管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact1'],
  //     },
  //     {
  //       path: `${pre}pactManagement2`,
  //       title: 'TSQ政府管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact2'],
  //     },
  //     {
  //       path: `${pre}pactManagement3`,
  //       title: '案例申报管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact3'],
  //     },
  //     {
  //       path: `${pre}pactManagement4`,
  //       title: '研讨会管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact4'],
  //     },
  //     {
  //       path: `${pre}pactManagement5`,
  //       title: '协会管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact5'],
  //     },
  //     {
  //       path: `${pre}pactManagement6`,
  //       title: '标准化组织管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact6'],
  //     },
  //     {
  //       path: `${pre}pactManagement7`,
  //       title: '拜访记录管理',
  //       auth: ['enterpriseManagement', 'enterpriseManagementPact7'],
  //     },
  //   ],
  // },
  ]
};