import Vue from 'vue';
import Vuex from 'vuex';
import admin from "./modules/admin";
import rect from "./modules/rect";
import pageCache from "./modules/pageCache";
import pcSaiBaoManagement from "./modules/pcSaiBaoManagement";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    admin: admin,
    rect: rect,
    pageCache: pageCache,
    pcSaiBaoManagement: pcSaiBaoManagement
  }
});