import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/posterManagement',
  name: 'posterManagement',
  component: BasicLayout,
  meta: meta,
  children: [{
    path: 'list',
    name: 'posterManagementList',
    meta: {
      title: '广告屏管理',
      auth: ['posterManagement', 'posterManagementIndex']
    },
    component: function component() {
      return import('@/pages/posterManagement/list');
    }
  }, {
    path: 'edit',
    name: 'posterManagementEdit',
    meta: {
      title: '广告屏管理',
      showSider: true,
      auth: ['posterManagement', 'posterManagementEdit']
    },
    component: function component() {
      return import('@/pages/posterManagement/edit');
    }
  }]
};