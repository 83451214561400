import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * 布局配置
 * */
import screenfull from 'screenfull';
import Setting from '@/setting';
export default {
  namespaced: true,
  state: _objectSpread(_objectSpread({}, Setting.layout), {}, {
    isMobile: false,
    // 是否为手机
    isTablet: false,
    // 是否为平板
    isDesktop: true,
    // 是否为桌面
    isFullscreen: false // 是否切换到了全屏
  }),
  mutations: {
    /**
         * @description 设置设备类型
         * @param {Object} state vuex state
         * @param {String} type 设备类型，可选值为 Mobile、Tablet、Desktop
         */
    setDevice: function setDevice(state, type) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = false;
      state["is".concat(type)] = true;
    },
    /**
         * @description 修改 menuCollapse
         * @param {Object} state vuex state
         * @param {Boolean} collapse 折叠状态
         * */
    updateMenuCollapse: function updateMenuCollapse(state, collapse) {
      state.menuCollapse = collapse;
    },
    /**
         * @description 设置全屏状态
         * @param {Object} state vuex state
         * @param {Boolean} isFullscreen vuex
         * */
    setFullscreen: function setFullscreen(state, isFullscreen) {
      state.isFullscreen = isFullscreen;
    },
    /**
         * @description 更改指定布局配置
         * @param {Object} state vuex state
         * @param {Object} key layout 名称，对应 Setting.layout
         * @param {Object} value layout 值
         * */
    updateLayoutSetting: function updateLayoutSetting(state, _ref) {
      var key = _ref.key,
        value = _ref.value;
      state[key] = value;
    }
  },
  actions: {
    /**
         * @description 初始化监听全屏状态
         */
    listenFullscreen: function listenFullscreen(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        if (screenfull.enabled) {
          screenfull.on('change', function () {
            if (!screenfull.isFullscreen) {
              commit('setFullscreen', false);
            }
          });
        }
        // end
        resolve();
      });
    },
    /**
         * @description 切换全屏
         */
    toggleFullscreen: function toggleFullscreen(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
          commit('setFullscreen', false);
        } else {
          screenfull.request();
          commit('setFullscreen', true);
        }
        // end
        resolve();
      });
    }
  }
};