var rects = [{
  'name': 'avatar',
  'label': '头像',
  'type': 'img',
  'src': require('@/assets/images/user.png'),
  'width': 40,
  'height': 40,
  'top': 10,
  'left': 10,
  'draggable': true,
  'resizable': true,
  'minw': 30,
  'minh': 30,
  'axis': 'both',
  'parentLim': true,
  'snapToGrid': false,
  'aspectRatio': false,
  'zIndex': 2,
  'active': false
}, {
  'name': 'nick_name',
  'label': '用户昵称',
  'type': 'text',
  'text': '用户昵称',
  'font_size': 16,
  'width': 70,
  'height': 18,
  'top': 20,
  'left': 60,
  'draggable': true,
  'resizable': true,
  'minw': 10,
  'minh': 10,
  'axis': 'both',
  'parentLim': true,
  'snapToGrid': false,
  'aspectRatio': true,
  'zIndex': 3,
  'color': '#333',
  'active': false
}];
var state = {
  rects: rects
};
var mutations = {
  ENABLE_ACTIVE: function ENABLE_ACTIVE(state, id) {
    state.rects[id].active = true;
  },
  DISABLE_ACTIVE: function DISABLE_ACTIVE(state, id) {
    state.rects[id].active = false;
  },
  ENABLE_ASPECT: function ENABLE_ASPECT(state, id) {
    state.rects[id].aspectRatio = true;
  },
  DISABLE_ASPECT: function DISABLE_ASPECT(state, id) {
    state.rects[id].aspectRatio = false;
  },
  ENABLE_DRAGGABLE: function ENABLE_DRAGGABLE(state, id) {
    state.rects[id].draggable = true;
  },
  DISABLE_DRAGGABLE: function DISABLE_DRAGGABLE(state, id) {
    state.rects[id].draggable = false;
  },
  ENABLE_RESIZABLE: function ENABLE_RESIZABLE(state, id) {
    state.rects[id].resizable = true;
  },
  DISABLE_RESIZABLE: function DISABLE_RESIZABLE(state, id) {
    state.rects[id].resizable = false;
  },
  ENABLE_SNAP_TO_GRID: function ENABLE_SNAP_TO_GRID(state, id) {
    state.rects[id].snapToGrid = true;
  },
  DISABLE_SNAP_TO_GRID: function DISABLE_SNAP_TO_GRID(state, id) {
    state.rects[id].snapToGrid = false;
  },
  ENABLE_BOTH_AXIS: function ENABLE_BOTH_AXIS(state, id) {
    state.rects[id].axis = 'both';
  },
  ENABLE_NONE_AXIS: function ENABLE_NONE_AXIS(state, id) {
    state.rects[id].axis = 'none';
  },
  ENABLE_X_AXIS: function ENABLE_X_AXIS(state, id) {
    state.rects[id].axis = 'x';
  },
  ENABLE_Y_AXIS: function ENABLE_Y_AXIS(state, id) {
    state.rects[id].axis = 'y';
  },
  ENABLE_PARENT_LIMITATION: function ENABLE_PARENT_LIMITATION(state, id) {
    state.rects[id].parentLim = true;
  },
  DISABLE_PARENT_LIMITATION: function DISABLE_PARENT_LIMITATION(state, id) {
    state.rects[id].parentLim = false;
  },
  CHANGE_ZINDEX: function CHANGE_ZINDEX(state, payload) {
    state.rects[payload.id].zIndex = payload.zIndex;
  },
  CHANGE_HEIGHT: function CHANGE_HEIGHT(state, payload) {
    state.rects[payload.id].height = payload.height;
  },
  CHANGE_WIDTH: function CHANGE_WIDTH(state, payload) {
    state.rects[payload.id].width = payload.width;
  },
  CHANGE_TOP: function CHANGE_TOP(state, payload) {
    state.rects[payload.id].top = payload.top;
  },
  CHANGE_LEFT: function CHANGE_LEFT(state, payload) {
    state.rects[payload.id].left = payload.left;
  },
  CHANGE_MINH: function CHANGE_MINH(state, payload) {
    state.rects[payload.id].minh = payload.minh;
  },
  CHANGE_MINW: function CHANGE_MINW(state, payload) {
    state.rects[payload.id].minw = payload.minw;
  },
  CHANGE_FONTSIZE: function CHANGE_FONTSIZE(state, payload) {
    state.rects[payload.id].font_size = payload.fontSize;
  },
  CHANGE_RECTDATA: function CHANGE_RECTDATA(state, payload) {
    state.rects = payload;
  }
};
var actions = {
  setActive: function setActive(_ref, _ref2) {
    var commit = _ref.commit,
      state = _ref.state;
    var id = _ref2.id;
    for (var i = 0, l = state.rects.length; i < l; i++) {
      if (i === id) {
        commit('ENABLE_ACTIVE', i);
        continue;
      }
      commit('DISABLE_ACTIVE', i);
    }
  },
  unsetActive: function unsetActive(_ref3, _ref4) {
    var commit = _ref3.commit;
    var id = _ref4.id;
    commit('DISABLE_ACTIVE', id);
  },
  toggleDraggable: function toggleDraggable(_ref5, _ref6) {
    var commit = _ref5.commit,
      state = _ref5.state;
    var id = _ref6.id;
    if (!state.rects[id].draggable) {
      commit('ENABLE_DRAGGABLE', id);
    } else {
      commit('DISABLE_DRAGGABLE', id);
    }
  },
  toggleResizable: function toggleResizable(_ref7, _ref8) {
    var commit = _ref7.commit,
      state = _ref7.state;
    var id = _ref8.id;
    if (!state.rects[id].resizable) {
      commit('ENABLE_RESIZABLE', id);
    } else {
      commit('DISABLE_RESIZABLE', id);
    }
  },
  toggleParentLimitation: function toggleParentLimitation(_ref9, _ref10) {
    var commit = _ref9.commit,
      state = _ref9.state;
    var id = _ref10.id;
    if (!state.rects[id].parentLim) {
      commit('ENABLE_PARENT_LIMITATION', id);
    } else {
      commit('DISABLE_PARENT_LIMITATION', id);
    }
  },
  toggleSnapToGrid: function toggleSnapToGrid(_ref11, _ref12) {
    var commit = _ref11.commit,
      state = _ref11.state;
    var id = _ref12.id;
    if (!state.rects[id].snapToGrid) {
      commit('ENABLE_SNAP_TO_GRID', id);
    } else {
      commit('DISABLE_SNAP_TO_GRID', id);
    }
  },
  setAspect: function setAspect(_ref13, _ref14) {
    var commit = _ref13.commit;
    var id = _ref14.id;
    commit('ENABLE_ASPECT', id);
  },
  unsetAspect: function unsetAspect(_ref15, _ref16) {
    var commit = _ref15.commit;
    var id = _ref16.id;
    commit('DISABLE_ASPECT', id);
  },
  setWidth: function setWidth(_ref17, _ref18) {
    var commit = _ref17.commit;
    var id = _ref18.id,
      width = _ref18.width;
    commit('CHANGE_WIDTH', {
      id: id,
      width: width
    });
  },
  setHeight: function setHeight(_ref19, _ref20) {
    var commit = _ref19.commit;
    var id = _ref20.id,
      height = _ref20.height;
    commit('CHANGE_HEIGHT', {
      id: id,
      height: height
    });
  },
  setTop: function setTop(_ref21, _ref22) {
    var commit = _ref21.commit;
    var id = _ref22.id,
      top = _ref22.top;
    commit('CHANGE_TOP', {
      id: id,
      top: top
    });
  },
  setLeft: function setLeft(_ref23, _ref24) {
    var commit = _ref23.commit;
    var id = _ref24.id,
      left = _ref24.left;
    commit('CHANGE_LEFT', {
      id: id,
      left: left
    });
  },
  setFontSize: function setFontSize(_ref25, _ref26) {
    var commit = _ref25.commit;
    var id = _ref26.id,
      fontSize = _ref26.fontSize;
    commit('CHANGE_FONTSIZE', {
      id: id,
      fontSize: fontSize
    });
  },
  changeXLock: function changeXLock(_ref27, _ref28) {
    var commit = _ref27.commit,
      state = _ref27.state;
    var id = _ref28.id;
    switch (state.rects[id].axis) {
      case 'both':
        commit('ENABLE_Y_AXIS', id);
        break;
      case 'x':
        commit('ENABLE_NONE_AXIS', id);
        break;
      case 'y':
        commit('ENABLE_BOTH_AXIS', id);
        break;
      case 'none':
        commit('ENABLE_X_AXIS', id);
        break;
    }
  },
  changeYLock: function changeYLock(_ref29, _ref30) {
    var commit = _ref29.commit,
      state = _ref29.state;
    var id = _ref30.id;
    switch (state.rects[id].axis) {
      case 'both':
        commit('ENABLE_X_AXIS', id);
        break;
      case 'x':
        commit('ENABLE_BOTH_AXIS', id);
        break;
      case 'y':
        commit('ENABLE_NONE_AXIS', id);
        break;
      case 'none':
        commit('ENABLE_Y_AXIS', id);
        break;
    }
  },
  changeZToBottom: function changeZToBottom(_ref31, _ref32) {
    var commit = _ref31.commit,
      state = _ref31.state;
    var id = _ref32.id;
    if (state.rects[id].zIndex === 1) {
      return;
    }
    commit('CHANGE_ZINDEX', {
      id: id,
      zIndex: 1
    });
    for (var i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === state.rects.length) {
          continue;
        }
        commit('CHANGE_ZINDEX', {
          id: i,
          zIndex: state.rects[i].zIndex + 1
        });
      }
    }
  },
  changeZToTop: function changeZToTop(_ref33, _ref34) {
    var commit = _ref33.commit,
      state = _ref33.state;
    var id = _ref34.id;
    if (state.rects[id].zIndex === state.rects.length) {
      return;
    }
    commit('CHANGE_ZINDEX', {
      id: id,
      zIndex: state.rects.length
    });
    for (var i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === 1) {
          continue;
        }
        commit('CHANGE_ZINDEX', {
          id: i,
          zIndex: state.rects[i].zIndex - 1
        });
      }
    }
  },
  setMinWidth: function setMinWidth(_ref35, _ref36) {
    var commit = _ref35.commit;
    var id = _ref36.id,
      width = _ref36.width;
    commit('CHANGE_MINW', {
      id: id,
      minw: width
    });
  },
  setMinHeight: function setMinHeight(_ref37, _ref38) {
    var commit = _ref37.commit;
    var id = _ref38.id,
      height = _ref38.height;
    commit('CHANGE_MINH', {
      id: id,
      minh: height
    });
  },
  setRectData: function setRectData(_ref39, data) {
    var commit = _ref39.commit;
    commit('CHANGE_RECTDATA', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};