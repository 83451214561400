import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/authManagement',
  name: 'authManagement',
  redirect: {
    name: 'roleList'
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'roleList',
    name: 'roleList',
    meta: {
      title: '角色管理',
      auth: ['authManagement', 'roleList']
    },
    component: function component() {
      return import('@/pages/authManagement/roleList');
    }
  }, {
    path: 'accountList',
    name: 'accountList',
    meta: {
      title: '账号管理',
      auth: ['authManagement', 'accountList']
    },
    component: function component() {
      return import('@/pages/authManagement/accountList');
    }
  }, {
    path: 'menuList',
    name: 'menuList',
    meta: {
      title: '菜单管理',
      auth: ['authManagement', 'menuList']
    },
    component: function component() {
      return import('@/pages/authManagement/menuList');
    }
  }, {
    path: 'logList',
    name: 'logList',
    meta: {
      title: '操作日志管理',
      auth: ['authManagement', 'logList']
    },
    component: function component() {
      return import('@/pages/authManagement/logList');
    }
  }]
};