import BasicLayout from '@/layouts/basic-layout';
import EmptyLayout from '@/layouts/empty-layout';
var meta = {
  auth: true
};
export default {
  path: '/enterpriseManagement',
  name: 'enterpriseManagement',
  component: BasicLayout,
  meta: meta,
  children: [{
    path: 'management',
    name: 'enterpriseManagementIndex',
    meta: {
      title: '企业画像',
      auth: ['enterpriseManagement', 'enterpriseManagementIndex'],
      children: ['enterpriseReportFigure']
    },
    component: function component() {
      return import('@/pages/enterprise/management');
    }
  }, {
    path: 'intermediaryManagement',
    name: 'enterpriseIntermediaryManagement',
    meta: {
      title: '企业中间库',
      auth: ['enterpriseManagement', 'enterpriseIntermediaryManagement']
    },
    component: function component() {
      return import('@/pages/enterprise/intermediaryManagement');
    }
  }, {
    path: 'pactManagement1',
    name: 'enterpriseManagementPact1',
    meta: {
      title: '合同管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact1']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement2',
    name: 'enterpriseManagementPact2',
    meta: {
      title: 'TSQ政府管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact2']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement3',
    name: 'enterpriseManagementPact3',
    meta: {
      title: '案例申报管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact3']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement4',
    name: 'enterpriseManagementPact4',
    meta: {
      title: '研讨会管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact4']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement5',
    name: 'enterpriseManagementPact5',
    meta: {
      title: '协会管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact5']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement6',
    name: 'enterpriseManagementPact6',
    meta: {
      title: '标准化组织管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact6']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }, {
    path: 'pactManagement7',
    name: 'enterpriseManagementPact7',
    meta: {
      title: '拜访记录管理',
      auth: ['enterpriseManagement', 'enterpriseManagementPact7']
    },
    component: function component() {
      return import('@/pages/enterprise/pactManagement');
    }
  }]
};