import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.index-of";
import cookies from "./util.cookies";
import log from "./util.log";
import db from "./util.db";
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
};
function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function (_ref) {
  var title = _ref.title,
    count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};
function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }
  setTimeout(task, 16);
}
export { requestAnimation };
export default util;

// 随机生成6位字母加数字的密码
export function generatePassword() {
  var list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  var number1 = list[Math.floor(Math.random() * 62)];
  var number2 = list[Math.floor(Math.random() * 62)];
  var number3 = list[Math.floor(Math.random() * 62)];
  var number4 = list[Math.floor(Math.random() * 62)];
  var number5 = list[Math.floor(Math.random() * 62)];
  var number6 = list[Math.floor(Math.random() * 62)];
  return number1 + number2 + number3 + number4 + number5 + number6;
}