var pre = '/authManagement/';
export default {
  path: '/authManagement',
  title: '权限管理',
  header: 'home',
  icon: 'ios-unlock',
  auth: ['authManagement'],
  children: [{
    path: "".concat(pre, "roleList"),
    title: '角色管理',
    auth: ['authManagement', 'roleList']
  }, {
    path: "".concat(pre, "accountList"),
    title: '账号管理',
    auth: ['authManagement', 'accountList']
  }, {
    path: "".concat(pre, "menuList"),
    title: '菜单管理',
    auth: ['authManagement', 'menuList']
  }]
};