import "core-js/modules/es6.regexp.replace";
import BasicLayout from '@/layouts/basic-layout';
import dashboard from "./modules/dashboard";
import learnManagement from "./modules/learnManagement";
import saibaoCourse from "./modules/saibaoCourse";
import forumManagement from "./modules/forumManagement";
import consultationManagement from "./modules/consultationManagement";
import operate from "./modules/operate";
import authManagement from "./modules/authManagement";
import pcSaiBaoManagement from "./modules/pcSaiBaoManagement";
import enterpriseReport from "./modules/enterpriseReport";
import enterpriseManagement from "./modules/enterpriseManagement";
import wechatOfficialAccountMessage from "./modules/wechatOfficialAccountMessage";
import posterManagement from "./modules/posterManagement";
import activityManagement from "./modules/activityManagement";
import formStruManagement from "./modules/formStruManagement";
import surveyStatistics from "./modules/surveyManage";
import thematicManagement from "./modules/thematicManagement";

/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  redirect: {
    name: 'dashboard'
  },
  component: BasicLayout,
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'dashboard'
    }
  }, {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: function component() {
      return import('@/pages/system/log');
    }
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }, {
    path: '/403',
    name: '403',
    meta: {
      title: '403'
    },
    component: function component() {
      return import('@/pages/system/error/403');
    }
  }]
}, dashboard, learnManagement, saibaoCourse, forumManagement, consultationManagement, operate, authManagement, pcSaiBaoManagement, enterpriseReport, enterpriseManagement, wechatOfficialAccountMessage, posterManagement, activityManagement, formStruManagement, surveyStatistics, thematicManagement];

/**
 * 在主框架之外显示
 */

var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/login');
  }
}, {
  path: '/test01',
  name: 'test01',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/test01');
  }
},
// 注册
{
  path: '/register',
  name: 'register',
  meta: {
    title: '$t:page.register.title'
  },
  component: function component() {
    return import('@/pages/account/register');
  }
},
// 重置密码
{
  path: '/reset',
  name: 'reset',
  meta: {
    title: '重置密码'
  },
  component: function component() {
    return import('@/pages/account/reset');
  }
},
// 注册结果
{
  path: '/register/result',
  name: 'register-result',
  meta: {
    auth: true,
    title: '注册结果'
  },
  component: function component() {
    return import('@/pages/account/register/result');
  }
}];

/**
 * 错误页面
 */

var errorPage = [
// {
//   path: '/403',
//   name: '403',
//   meta: {
//     title: '403'
//   },
//   component: () => import('@/pages/system/error/403')
// },
{
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '*',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/system/error/404');
  }
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);