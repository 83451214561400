export default {
  path: '/logManage',
  title: '日志管理',
  header: 'home',
  icon: 'ios-podium',
  auth: ['logManage'],
  children: [{
    path: '/loginLog',
    title: '登陆日志',
    header: 'home',
    auth: ['loginLogIndex', 'loginLog']
  }, {
    path: "/authManagement/logList",
    title: '操作日志管理',
    auth: ['authManagement', 'logList']
  }]
};