import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/thematicManagement',
  name: 'thematicManagement',
  component: BasicLayout,
  redirect: {
    name: 'thematicList'
  },
  meta: meta,
  children: [{
    path: 'thematicList',
    name: 'thematicList',
    meta: {
      title: '专题列表',
      auth: ['thematicManagement', 'thematicList']
    },
    component: function component() {
      return import('@/pages/thematicManagement/index.vue');
    }
  }, {
    path: 'thematicEdit',
    name: 'thematicEdit',
    meta: {
      title: '编辑专题',
      showSider: true,
      auth: ['thematicManagement', 'thematicEdit']
    },
    component: function component() {
      return import('@/pages/thematicManagement/edit.vue');
    }
  }, {
    path: 'thematicCreate',
    name: 'thematicCreate',
    meta: {
      title: '创建专题',
      showSider: true,
      auth: ['thematicManagement', 'thematicCreate']
    },
    component: function component() {
      return import('@/pages/thematicManagement/create.vue');
    }
  }]
};