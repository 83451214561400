import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _typeof from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import _defineProperty from "/drone/src/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import store from '@/store';
import util from '@/libs/util';
export default {
  install: function install(Vue, options) {
    // 快速打印 log
    Vue.prototype.$log = _objectSpread(_objectSpread({}, util.log), {}, {
      push: function push(data) {
        if (typeof data === 'string') {
          // 如果传递来的数据是字符串
          // 赋值给 message 字段
          // 为了方便使用
          // eg: this.$log.push('foo text')
          store.dispatch('admin/log/push', {
            message: data
          });
        } else if (_typeof(data) === 'object') {
          // 如果传递来的数据是对象
          store.dispatch('admin/log/push', data);
        }
      }
    });
  }
};