import "core-js/modules/es6.number.constructor";
import ApiSelect from "@/components/form/ApiSelect";
export default {
  name: 'searchFilter',
  props: {
    value: {
      type: Object
    },
    fieldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    maxLine: {
      type: Number,
      default: function _default() {
        return 2;
      }
    },
    // 默认最多展示的列数
    labelWidth: {
      type: [Number, String],
      default: 90
    },
    // 默认最多展示的列数
    labelPosition: {
      type: String,
      default: 'right'
    }
  },
  components: {
    ApiSelect: ApiSelect
  },
  data: function data() {
    return {
      grid: {
        xl: 8,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24
      },
      collapse: false,
      // 默认关闭
      data: {}
    };
  },
  computed: {
    actionGrid: function actionGrid() {
      var grid = {
        xl: 8,
        lg: 8,
        md: 24,
        sm: 24,
        xs: 24
      };
      if (this.fieldList.length === 1) {
        grid.md = 8;
      } else if (this.collapse && this.fieldList.length % 3 === 0) {
        grid.xl = 24;
        grid.lg = 24;
      }
      return grid;
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var data = {};
      for (var k in this.data) {
        if (this.data[k] !== '' && this.data[k] !== undefined && this.data[k] !== null) {
          data[k] = this.data[k];
        }
      }
      this.$emit('on-submit', data);
    },
    handleReset: function handleReset() {
      this.$refs.form.resetFields();
      this.data = {};
      this.$emit('on-reset', {});
    }
  }
};