import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
export default {
  path: '/activityManagement',
  name: 'activityManagement',
  component: BasicLayout,
  redirect: {
    name: 'activityList'
  },
  meta: meta,
  children: [{
    path: 'activityList',
    name: 'activityList',
    meta: {
      title: '活动列表',
      auth: ['activityOperate', 'activityList']
    },
    component: function component() {
      return import('@/pages/activityOperate/activityList/index.vue');
    }
  }, {
    path: 'createActivityList',
    name: 'createActivityList',
    meta: {
      title: '创建活动列表',
      showSider: true,
      auth: ['activityOperate', 'createActivityList']
    },
    component: function component() {
      return import('@/pages/activityOperate/activityList/createActivityList.vue');
    }
  }, {
    path: 'activityStatistics',
    name: 'activityStatistics',
    meta: {
      title: '活动统计',
      showSider: true,
      auth: ['activityOperate', 'activityStatistics']
    },
    component: function component() {
      return import('@/pages/activityOperate/activityList/statistics.vue');
    }
  }]
};