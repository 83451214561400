var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Select", _vm._b({
    attrs: {
      placeholder: _vm.setting.placeholder || "\u8BF7\u9009\u62E9".concat(_vm.setting.label),
      loading: _vm.loading,
      clearable: "",
      type: "number"
    },
    on: {
      "on-open-change": _vm.handleOpenChange,
      "on-change": _vm.handleChange
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, "Select", _vm.setting.componentProps, false), [_vm._l(_vm.options, function (k, i) {
    return _c("Option", {
      key: i,
      attrs: {
        value: k[_vm.setting.valueField]
      }
    }, [_vm._v("\n    " + _vm._s(k[_vm.setting.labelField]) + "\n  ")]);
  }), _vm.totalPage > 1 ? _c("Page", {
    staticClass: "ivu-text-center",
    attrs: {
      total: _vm.total,
      size: "small",
      "show-total": "",
      "page-size": _vm.pageSize,
      current: _vm.pageNum
    },
    on: {
      "on-change": _vm.handlePageName
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };